import {addDays, format, parseISO, startOfWeek} from "date-fns";
import { ru, uk, enUS, es } from "date-fns/locale/index.js";

const availableLocales: { [key: string]: Locale } = {
    ru: ru,
    uk: uk,
    en: enUS,
    es: es,
}

export function dateFormatter(locale: string) {
    function formatSessionDateTime(dateTime: string|Date) {
        if (typeof dateTime === 'string') {
            dateTime = parseISO(dateTime);
        }

        return format(dateTime, 'do MMM HH:mm', {
            locale: availableLocales[locale],
        })
    }

    function formatHours(time: string|Date) {
      if (typeof time === 'string') {
        time = parseISO(time);
      }

      return format(time, 'HH:mm', {
        locale: availableLocales[locale],
      })
    }

    function formatChatMessageTime(time: string|Date) {
        if(typeof time === 'string') {
            time = parseISO(time)
        }

        return format(time, 'dd MMMM HH:mm', {
            locale: availableLocales[locale],
        })
    }

    function formatCalendarMonthName(date: Date|string) {
      if (typeof date === 'string') {
        date = parseISO(date);
      }

        return format(date, 'LLLL, yyyy', {
            locale: availableLocales[locale],
        })
    }

    function formatCalendarDayName(date: Date) {
        return format(date, 'do MMMM yyyy', {
            locale: availableLocales[locale],
        })
    }

    function formatCalendarDateRange(startDate: Date, endDate: Date) {
        const formattedStartDate = format(startDate, 'do MMMM yyyy', {locale: availableLocales[locale]});
        const formattedEndDate = format(endDate, 'do MMMM yyyy', {locale: availableLocales[locale]});
        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    function getNameDayOfWeek(dayNum: number) {
        const firstDOW = startOfWeek(new Date())
        return format(addDays(firstDOW, dayNum), 'EEEEEE', {
            weekStartsOn: 1, locale: availableLocales[locale]
        });
    }

    function formatCheckoutCalendarMonth(date: Date|string) {
        if (typeof date === 'string') {
            date = parseISO(date);
        }

        return format(date, 'MMMM', {
            locale: availableLocales[locale],
        })
    }

    function formatCheckoutCalendarDay(date: Date|string) {
        if (typeof date === 'string') {
            date = parseISO(date);
        }

        return format(date, 'd', {
            locale: availableLocales[locale],
        })
    }

    function formatCheckoutCalendarWeekdayName(date: Date|string) {
        if (typeof date === 'string') {
            date = parseISO(date);
        }

        return format(date, 'EEEE', {
            locale: availableLocales[locale],
        })
    }

    function formatFinanceDate(date: Date|string) {
        if (typeof date === 'string') {
            date = parseISO(date);
        }

        return format(date, 'dd/LL/Y HH:mm:ss', {
            locale: availableLocales[locale],
        })
    }

    return {
        formatSessionDateTime,
        formatChatMessageTime,
        formatHours,
        formatCalendarMonthName,
        formatCalendarDayName,
        formatCalendarDateRange,
        getNameDayOfWeek,

        formatCheckoutCalendarMonth,
        formatCheckoutCalendarDay,
        formatCheckoutCalendarWeekdayName,

        formatFinanceDate,
    }
}
